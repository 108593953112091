body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  transition-duration: 0.5s;
  color: #0072e5;
  text-decoration: none;
}

a:hover {
  opacity: 0.9;
}

.app-icon {
  transition-duration: 0.5s;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.app-icon:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.MuiCircularProgress-colorInfo {
  position: fixed;
  top: 50%;
  left: 50%;
}
